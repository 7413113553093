import Vue from 'vue';
import axios from 'axios';

import VueCookies from 'vue-cookies';
Vue.use(VueCookies)


const actions = {
  async setUserData({ commit }) {
    try {
      const domain = process.env.VUE_APP_DOMAIN + "/api/v1";
      const response = await axios.get(domain + '/users/me', {
        headers: {
          'Authorization': 'Bearer ' + Vue.$cookies.get('token')
        }
      });
      commit('SET_USER_DATA', response.data);
    } catch (error) {
      console.error(error);
    }
  }
};

export default actions;