import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import creationModule from "./modules/patient-creation";
import assessmentModule from "./modules/patient-assessment";
import homeOverview from "./modules/home-overview";
import headerStore from "./modules/header";
import sidebarStore from "./modules/sidebar";
import overviewPage from "./modules/overview-page";
import tabsStore from './modules/tabs';
import general from './modules/general';

const store = new Vuex.Store({
  modules: {
    creation: creationModule,
    assessment: assessmentModule,
    homeOverview: homeOverview,
    header: headerStore,
    sidebar: sidebarStore,
    overviewPage: overviewPage,
    tabs: tabsStore,
    general
  },
});

export default store;
