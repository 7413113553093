import { $logicTranslator } from "../helper/globalFunction";

export default {
  methods: {
    /**
     * Handle field state for mixins 
     * Translate disable /visibility statement
    */
    handleLogicStatement(field, stateType, fieldConfig) {
      if (this.logic[field] && this.logic[field][stateType]) {
        const statement = $logicTranslator(this.logic[field][stateType], fieldConfig);
        return eval(statement);
      }
  
      return stateType == 'disable' ? false : true;
    },
    /**
     * Handle update related field 
     * change if some field toggled
    */
    updateRelatedField(toggledField, affectedFields, object) {
      const page = this.$route.params.tab;

      if (object) {
        const { index, parent } = object;
        if (index !== null) {
          affectedFields.forEach(field => {
            this.content[page][parent][index][field] = 
              this.content[page][parent][index][toggledField] 
                ? null : this.content[page][parent][index][field];
          });
        }
      } else {
        affectedFields.forEach(field => {
          this.content[page][field] = 
            this.content[page][toggledField] ? null : this.content[page][field];
        });
      }
    }
  }
}