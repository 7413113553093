import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions({
      assignTabs: 'tabs/assignTabs',
      reconstructTabs: 'tabs/reconstructTabs',
      setAssessmentTab: 'assessment/setSelectedTab',
      setCreationTab: 'creation/setSelectedTab',
    }),
    // Execute when GET success
    checkFormStatus(page = null, currentPage, requestType, nav = null) {
      if (requestType === 'GET') {
        page = nav.find(el => el.page === currentPage);
        let pageIndex = nav.findIndex(el => el.page === currentPage);
        
        if (page.status === 'complete') {
          this.setAssessmentTab(nav[pageIndex+1].page);
          this.$router.push({
            name: this.$route.name,
            params: { 
              tab: nav[pageIndex+1].page,
              id: this.$route.params.id,
              state: this.$route.params.state || ''
            },
          });
          
          return;
        }
      // Execute when POST Success
      } else if (requestType === 'POST') {
        if (page === 'summaryPatientView') {
          this.setAssessmentTab('patientList');
          this.$router.push({
            name: 'summary',
            params: { tab: 'patientList' }
          })
        } else {
          if (this.$route.query.state) {
            this.$router.push({
              name: this.$route.name,
              params: { 
                tab: page,
                id: this.$route.params.id,
                state: this.$route.params?.state || ''
              },
              query: { state: this.$route.query.state }
            });  
          } else {
            this.$router.push({
              name: this.$route.name,
              params: { 
                tab: page, 
                id: this.$route.params.id,
                state: this.$route.params?.state
              },
            });
          }

          if (this.$route.path.includes('assessment')) this.setAssessmentTab(page);
          else this.setCreationTab(page);
        }
      }
    },
    adjustTab(nav) {
      // Set new navs, if there's any
      if (nav.length > 0) {
        this.assignTabs(nav);
        // Reconstruct nav, based on each of the status
        this.reconstructTabs();
      }
    }
  }
}