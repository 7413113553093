<template>
  <div id="app">
    <Header
      v-if="
        $route.meta.layout === 'dashboard' || $route.meta.layout === 'login'
      "
      :userData="userData"
    />
    <div id="content">
      <div></div>
      <div class="router-view">
        <router-view></router-view>
      </div>
      <div></div>
      <!-- Sidebar -->
      <sidebar :goTo="goTo" :logout="logout" />
    </div>

    <br />

    <Footer
      v-if="
        $route.meta.layout === 'dashboard' || $route.meta.layout === 'login'
      "
    />
  </div>
</template>

<script>
import Header from "./components/layouts/Header.vue";
import Sidebar from "./components/layouts/Sidebar.vue";
import Footer from "./components/layouts/Footer.vue";
import Swal from "sweetalert2";

import { mapActions, mapGetters } from 'vuex';

export default {
  name: "App",
  components: {
    Header,
    Sidebar,
    Footer,
  },
  computed: {
    ...mapGetters({
      userData: 'general/getUserData',
    })
  },
  watch: {
    $route: {
      handler(val) {
        const isUserDataExists = Object.keys(this.userData).length;
        if (val.name !== "login" && !isUserDataExists) this.setUserData();
      },
      deep: true,
    },
  },
  mounted() {
    // remove cookie token when session ends
    if (this.$route.meta.layout === "dashboard") {
      if (!sessionStorage.getItem("token")) {
        this.handleEndSessionSuccess();
      }
    }
  },
  methods: {
    ...mapActions({
      setUserData: 'general/setUserData',
    }),
    goTo(path) {
      if (this.$route.path !== path && path.includes("profile")) {
        this.$router.push(`${path}/${this.userData.id}`);
      } else this.$router.push(path);
    },
    logout() {
      Swal.fire({
        title: "Confirm",
        text: "Are you sure you want to logout ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, log me out",
        cancelButtonColor: "red",
        customClass: {
          confirmButton: "confirmButton",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.endSession();
        }
      });
    },
    endSession() {
      this.$vToastify.success({
        title: "Please wait",
        body: "Logging out",
        mode: "loader",
      });
      const queryString = require("query-string");
      const payload = {
        refreshToken: this.$cookies.get("refreshToken"),
      };

      this.$requestService
        .auth(
          this.$apiEndpoints.logout,
          queryString.stringify(payload),
          this.handleEndSessionSuccess,
          (e) => console.log(e)
        )
        .then(() => {
          this.$vToastify.stopLoader();
        });
    },
    handleEndSessionSuccess() {
      this.$cookies.remove("token");
      this.$cookies.remove("refreshToken");
      this.$router.push("/web/login");
    },
  },
};
</script>

<style lang="scss">
#app {
  display: flex;
  flex-direction: column;
}
</style>

