import moment from 'moment';

export default {
  methods: {
    // construct assessment column if 3 selected
    async constructColumn(data) {
      let dates = data.map(el => ({
        id: el.id,
        date: moment(el.creationDate).format('DD-MM-YYYY')
      }));
      
      await this.combineExistingColumn(dates);
      const columnNames = Object.keys(this.options);
      columnNames.forEach(el => {
        this.mapTableData({ 
          type: el,
          data
        });  
      })
    },
    // combine existing column with chosen assessment dates
    combineExistingColumn(dates) {
      const columnItems = Object.keys(this.options);

      columnItems.forEach(el => {
        if (el !== 'patients') {
          this.options[el].columns = [
            ...this.options[el].columns,
            ...dates.map(val => ({
              id: val.id, key: val.id, label: val.date
            }))
          ]
        }
      });
    },
    // prepare object for mapping data - DEPRECATED
    combineExistingContent(dates) {
      const columnItems = Object.keys(this.content.pdfPage);
      
      columnItems.forEach(el => {
        if (el !== 'patients') {
          this.content.pdfPage[el] = [
            ...this.content.pdfPage[el],
            ...dates.map(date => ({
              [date]: null,
            }))
          ]
        }
      });
    },
    mapTableData({ type, data }) {
      data.forEach((el, idx) => {
        // sum mrc muscle test
        data[idx].assessmentPages[1]['mRCMuscleTest'] = el.mrcScaleSum;

        this.options[type].columns.forEach(val => {
          if (el.id === val.id) {
            if (type === 'evaluation') {
              this.assignEvaluation(
                data, 
                val.id, 
                idx, 
              );
            } else {
              switch (type) {
                case 'mRCScale':
                  this.assignMRCScale(el.assessmentPages[1], val.id)
                  break;
                case 'modifiedRankingScale':
                  this.assignModifiedRankingScale(el.assessmentPages[6], val.id);
                  break;
                case 'respiratoryAssessment':
                  this.assignRespiratory(el.assessmentPages[4], val.id)
                  break;
                case 'muscoskeletalMotorAssessment':
                  this.assignMuscoskeletalMotor(el.assessmentPages[5], val.id);
                  break;
                case 'pulmonaryFunctionTest':
                  this.assignPulmonaryFunctionTest(el.assessmentPages[2], val.id);
                  break;
                case 'sixMinuteWalkTest':
                  this.assignSixMinuteWalkTest(el.assessmentPages[0], val.id);
                  break;
                case 'motorFunctionalActivities':
                  this.assignMotorFunctional(el.assessmentPages[3], val.id);
                  break;
                case 'waltonGardnerMewinScale':
                  this.assignWaltonGardner(el.assessmentPages[9], val.id);
                  break;
                case 'fatigueSeverityScale':
                  this.assignFatigueSeverityScale(el.assessmentPages[8], val.id);
                  break;
                case 'rotterdam9ItemHandicapScale':
                  this.assignRotterdamScale(el.assessmentPages[7], val.id);
                  break;
                case 'vitalSigns':
                  this.assignVitalSigns(el.assessmentPages[10], val.id);
                  break;
                default:
                  break;
              }  
            }
          } 
        })
      })
    },
  }
}