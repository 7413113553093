<template>
  <div id="radio-components">
    <b-form-group :description="description">
      <label
        v-if="label !== ''"
        :for="name"
      > 
        {{ label }}
        <span
          v-if="mandatory"
          class="text-danger"
        > 
          *
        </span>
        <b-icon
          v-if="tooltipMessage"
          class="ml-2"
          icon="exclamation-circle-fill"
          v-b-tooltip.top="tooltipMessage"
        ></b-icon>
      </label>
      <b-form-radio-group
        v-if="type !== 'single'"
        :id="`${name}-${index || ''}`"
        v-model="localValue"
        :stacked="type === 'stacked' ? true : false"
        :disabled="handleLogicStatement(name, 'disable')"
        @change="$emit('inputEvent', name, index)"
        @input="$emit('input', $event)"
      >
        <b-form-radio 
          v-for="(option, index) in options" 
          :key="index" 
          :id="option.text"
          :value="option.value"
          :name="name"
        >
          {{ option.text }}
        </b-form-radio>
      </b-form-radio-group>
      <div v-if="type === 'single'">
        <b-form-radio 
          v-model="localValue"
          :key="index" 
          :value="radioValue"
          :id="radioId"
          :name="name"
          @change="$emit('inputEvent', name, index)"
          @input="$emit('input', $event)"
        >
          {{ text }}
        </b-form-radio>
      </div>
      <!-- display error messages -->
      <error-message-new
        :index="index"
        :errors="errors"
        :fieldName="name"
      />
    </b-form-group>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    tooltipMessage: {
      type: String,
      default: null
    },
    name: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      default: null,
    },
    radioId: {
      type: [ Number, String ],
    },
    type: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    radioValue: {
      type: [ String, Number, Boolean ]
    },
    value: {
      type: [ String, Number, Boolean ]
    },
    description: {
      type: String,
      default: ''
    },
    model: {
      type: [String, Number, Boolean],
      default: null
    },
    options: {
      type: Array,
      default: () => ([])
    },
    parent: {
      type: String,
      default: null
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
    showError: {
      type: Boolean,
      default: true
    },
    mandatory: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => []
    },
    logic: {
      type: Object,
      default: () => ({})
    },
  },
  data: () => ({
    localValue: null,
  }),
  watch: {
    value: {
      handler(val) {
        this.localValue = val;
      },
      immediate: true,
    }
  },
}
</script>