<template>
  <div id="pompe-table" class="pompe-table">
    <table class="table table-sm table-bordered table-striped mt-3">
      <thead>
        <tr>
          <th v-if="checkableRows" width="20">
            <b-form-checkbox
              v-if="checkAllAvailable"
              id="checkbox-all"
              name="checkbox-1"
              :checked="isCheckedAll"
              :indeterminate="isIndeterminate"
              class="checkbox-table"
              @change="(val) => $emit('action:check-all', val)"
            />
          </th>
          <th v-if="showNumber">No</th>
          <th v-for="(column, idx) in columns" :key="idx" :width="column.width">
            {{ column.label }}
          </th>
        </tr>
      </thead>
      <tbody v-if="!loading">
        <tr v-for="(value, idx) in localData" :key="idx">
          <td v-if="checkableRows">
            <b-form-checkbox
              v-if="value.showCheck"
              class="checkbox-table"
              :id="`checkbox-${idx}`"
              :checked="value.checked"
              :name="`checkbox-${idx}`"
              @change="$emit('action:check-single', idx)"
            />
          </td>
          <td v-if="showNumber">{{ idx + 1 }}</td>
          <td v-for="(column, idx) in columns" :key="idx">
            <span v-if="!column.format">
              {{ value[column.key] }}
            </span>
            <span v-else>
              {{ column.format(value[column.key]) }}
            </span>

            <span v-if="column.key === 'action'">
              <slot name="action" :row="value">
                <b-dropdown
                  size="sm"
                  right
                  text="Actions"
                  variant="link"
                  toggle-class="anchor-link text-decoration-none"
                >
                  <b-dropdown-item
                    v-for="(action, idx) in column.actions"
                    :key="idx"
                    href="javascript:void(0)"
                    @click.prevent="action.execute(value)"
                  >
                    {{ action.label }}
                  </b-dropdown-item>
                </b-dropdown>
              </slot>
            </span>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td 
            :colspan="columns.length + 1"
            class="text-center"
          >
            <b-spinner small label="Small Spinner"></b-spinner>
          </td>
        </tr>
      </tbody>
    </table>
    
    <!-- <b-pagination
      v-if="showPagination"
      :key="localPagination.currentPage"
      style="margin: 0"
      v-model="localPagination.currentPage"
      :perPage="localPagination.perPage"
      :currentPage="localPagination.currentPage - 1"
      :totalRows="localPagination.totalRows"
      :align="paginationAlignment"
      size="sm"
      @click.native="
        $emit('fetch-data', {
          search: null,
          pagination: {
            page: localPagination.currentPage - 1,
            size: localPagination.perPage,
          },
        })
      "
    /> -->
  </div>
</template>

<script>
export default {
  name: 'PompeTable',
  props: {
    showPagination: {
      type: Boolean,
      default: true,
    },
    pagination: {
      type: Object,
      default: () => ({}),
    },
    showNumber: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    paginationAlignment: {
      type: String,
      default: 'right',
    },
    checkableRows: {
      type: Boolean,
      default: false,
    },
    checkAllAvailable: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    isIndeterminate() {
      const checks = this.localData.map((value) => value.checked);

      const totalData = checks.length;
      const totalSelectedData = checks.filter(Boolean).length;

      if (totalSelectedData === 0) {
        return false;
      }

      if (totalSelectedData < totalData) {
        return true;
      }

      return false;
    },
    isCheckedAll() {
      const checks = this.localData.map((value) => value.checked);

      const totalData = checks.length;
      const totalSelectedData = checks.filter(Boolean).length;

      if (totalSelectedData === totalData && totalData !== 0) {
        return true;
      }

      return false;
    },
  },
  watch: {
    pagination: {
      handler(val) {
        this.localPagination = {
          perPage: val.pageSize,
          currentPage: Number(val.pageNumber) + 1,
          totalRows: val.totalRows,
        };
      },
      deep: true,
      immediate: true,
    },
    data: {
      handler(val) {
        this.localData = [...val];
      },
      deep: true,
      immediate: true,
    },
    selectedAll: {
      handler(val) {
        this.localSelectedAll = val;
      },
      immediate: true,
    },
  },
  data: () => ({
    localPagination: {},
    localData: [],
    localSelectedAll: false,
  }),
};
</script>

<style lang="scss" scoped>
@import '@/style/color.scss';

.btn {
  border-radius: 0 !important;
  padding: 0.3em 0.3em 0.3em 0.5em !important;
}
.checkbox-table {
  margin-bottom: 0 !important;
  margin-right: 0 !important;
  margin-left: 5px;
}
table {
  font-size: 14px;

  thead th {
    font-weight: bold;
    background-color: $primary-ui;
    color: white;   
  }
}
</style>
