<template>
  <b-form-group>
    <label> {{ label }} </label>
    <br />
    <span class="detail-text text-muted">
      <slot name="content">
        {{ text !== null ? text : '-' }}
      </slot>
    </span>
  </b-form-group>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: null
    }
  }
};
</script>

<style></style>
