<template>
  <div id="autocomplete">
    <b-form-group :description="`Type in the value of ${placeholder}`">
      <label v-if="label !== ''" :for="name"> {{ label }} </label>
      <v-select 
        multiple
        style="line-height: 20px"
        v-model="value"
        :value="value"
        :reduce="option => option.value"
        label="text"
        :id="`${name}-${index || ''}`"
        :type="type"
        :options="options"
        :name="name"
        :size="size"
        :disabled="handleLogicStatement(name, 'disable') || isDisabled"
        :state="$hasField(name, errors)"
        @change="$emit('blurEvent', name)"
        @input="$emit('input', $event)"
      />
      <!-- display error messages -->
      <error-message
        v-if="!$hasField({ name, errors, index }) && showError"
        :errors="errors"
        :fieldName="name"
      />
    </b-form-group>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

import { $hasField } from '@/helper/globalFunction.js';

export default {
  components: {
    'v-select': vSelect,
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
    },
    value: {
      type: Array,
      default: () => ([])
    },
    parent: {
      type: String,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
    size: {
      type: String,
      default: "sm",
    },
    isDisabled: {
      type: Boolean,
    },
    showError: {
      type: Boolean,
      default: true
    },
    errors: {
      type: [Object, Array],
    },
    logic: {
      type: Object,
    },
    options: {
      type: Array,
      required: true,
    },
    content: {
      type: Object,
    },
  },
  methods: {
    $hasField,
  }
};
</script>

<style></style>
