const mutations = {
  SET_VISIT_TAB(state, payload) {
    state.selectedTab = payload;
  },
  SET_PATIENT_REF(state, payload) {
    state.patientRef = payload;
  },
  SET_PATIENT_DATA(state, payload) {
    state.patientData = {...payload};
  }
};

export default mutations;
