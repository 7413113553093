// list of route here

const routes = [
  {
    path: "/",
    redirect: "/web/summary/summaryPatientView",
  },
  {
    path: "/web/summary",
    redirect: "/web/summary/summaryPatientView",
  },
  {
    path: "/web",
    redirect: "/web/summary/summaryPatientView",
  },
  {
    path: "/web/login",
    name: "login",
    component: () => import("../pages/Login.vue"),
    meta: {
      needAuth: false,
      layout: "login",
    },
  },
  {
    path: '/web/request-password',
    name: 'RequestPassword',
    component: () => import("../pages/ForgotPassword/SendEmail"),
    meta: {
      needAuth: false,
      layout: 'login'
    }
  },
  {
    path: '/web/new-password',
    name: 'NewPassword',
    component: () => import("../pages/ForgotPassword/PassChange"),
    meta: {
      needAuth: false,
      layout: 'login'
    }
  },
  {
    path: "/web/summary/:tab",
    name: "summary",
    component: () => import("../pages/HomeOverview/Home.vue"),
    meta: {
      needAuth: true,
      layout: "dashboard",
    },
    children: [
      {
        path: 'create-hospital',
        name: 'CreateHospital',
        component: () => import("@/pages/HomeOverview/Sections/HospitalManagement/Create.vue"),
        meta: {
          needAuth: true,
          layout: "dashboard",
        },
      },
      {
        path: 'edit-hospital/:id',
        name: 'EditHospital',
        component: () => import('@/pages/HomeOverview/Sections/HospitalManagement/Create.vue'),
        meta: {
          needAuth: true,
          layout: "dashboard"
        }
      },
      {
        path: 'create-manager',
        name: 'CreateManager',
        component: () => import("@/pages/HomeOverview/Sections/DataManager/Create.vue"),
        meta: {
          needAuth: true,
          layout: "dashboard",
        },
      },
      {
        path: 'edit-manager/:id',
        name: 'EditManager',
        component: () => import('@/pages/HomeOverview/Sections/DataManager/Create.vue'),
        meta: {
          needAuth: true,
          layout: "dashboard"
        }
      },
      {
        path: 'create-physicians',
        name: 'CreatePhysicians',
        component: () => import("@/pages/HomeOverview/Sections/Physicians/Create.vue"),
        meta: {
          needAuth: true,
          layout: "dashboard",
        },
      },
      {
        path: 'edit-physicians/:id',
        name: 'EditPhysicians',
        component: () => import('@/pages/HomeOverview/Sections/Physicians/Create.vue'),
        meta: {
          needAuth: true,
          layout: "dashboard"
        }
      } 
    ]
  },
  {
    path: "/web/add-new-patient/:tab/:id?/:state?",
    name: "addPatient",
    component: () => import("../pages/Patient/Creation/Index.vue"),
    meta: {
      needAuth: true,
      layout: "dashboard",
    },
  },
  {
    path: "/web/assessment-data/:tab/:id?/:state?",
    name: "assessmentData",
    component: () => import("../pages/Patient/Assessment/Index.vue"),
    meta: {
      needAuth: true,
      layout: "dashboard",
      breadcrumbs: [
        { key: 'home', text: 'Home' },
        { key: 'assessment-data', text: 'Assessment Data', active: true },
      ]
    },
  },
  {
    path: "/web/patient-overview/:tab/:id",
    name: "patientOverview",
    component: () => import("../pages/Patient/Overview/Index.vue"),
    meta: {
      needAuth: true,
      layout: "dashboard",
      breadcrumbs: [
        { key: 'home', text: 'Home' },
        { key: 'overview', text: 'Overview', active: true },
      ]
    }
  },
  {
    path: "/web/profile/:id?",
    name: "profilePage",
    component: () => import("../pages/Profile.vue"),
    meta: {
      needAuth: true,
      layout: "dashboard",
    },
  },
  {
    path: "/web/additional-documents",
    name: "downloadArea",
    component: () => import("../pages/DownloadArea/Index.vue"),
    meta: {
      needAuth: true,
      layout: "dashboard",
    },
  },
  {
    path: "/web/information-for-healthcare-professionals",
    name: "professionalInfo",
    component: () => import("../pages/HealthcareInfo/Index.vue"),
    meta: {
      needAuth: true,
      layout: "dashboard",
    },
  },
  {
    path: "/web/help-section",
    name: "helpSection",
    component: () => import("../pages/HelpSection.vue"),
    meta: {
      needAuth: true,
      layout: "dashboard"
    }
  },
  {
    path: '/web/assessment-pdf-template',
    name: 'assessmentPdf',
    component: () => import("../pages/PdfTemplates/Assessment/Container.vue"),
    meta: {
      needAuth: true,
      layout: 'pdf'
    }
  },
  {
    path: '/web/evaluation-pdf-template',
    name: 'evaluationPdf',
    component: () => import("../pages/PdfTemplates/Evaluation/Index.vue"),
    meta: {
      needAuth: true,
      layout: 'pdf'
    }
  }
];

export default routes;
