import Vue from "vue";

// call domain from env file
const startProcessDomain = process.env.VUE_APP_DOMAIN + "/api/backend";
const v1Domain = process.env.VUE_APP_DOMAIN + "/api/v1";

// list of endpoints
const $apiEndpoints = {
  startProcessForm: startProcessDomain + "/process/1",
  startProcessSummary: startProcessDomain + "/process/0",
  startProcessAssessment: startProcessDomain + "/process/2",
  startProcessNewVisit: startProcessDomain + "/process/3",
  startProcessOverview: startProcessDomain + "/process/6",
  startProcessAssessmentOverview: startProcessDomain + "/process/7",
  startProcessEditAssessment: startProcessDomain + "/process/8",
  startProcessEditVisit: startProcessDomain + "/process/9",
  getSpecificPage: startProcessDomain + "/page",
  summaryPatientView: v1Domain + '/patients',
  secondopinions: v1Domain + '/secondopinions',
  assessments: v1Domain + '/assessments',
  visit: v1Domain + '/visits',
  reactivate: v1Domain + '/patients/:id/discontinuation',
  deactivatePatient: v1Domain + '/patients/:id/deactivate',
  reactivatePatient: v1Domain + '/patients/:id/reactivate',
  hospitals: v1Domain + '/hospitals',
  patients: v1Domain + '/patients',
  users: v1Domain + '/users',
  download: v1Domain + '/files',
  staticData: v1Domain + '/staticdata',
  downloadHelper: v1Domain + '/download/helper',
  login: v1Domain + '/auth/login',
  logout: v1Domain + '/auth/logout',
  getCurrentUser: v1Domain + '/users/me',
  passwordRequest: v1Domain + '/users/request-passreset',
  passwordReset: v1Domain + '/users/do-passreset',
  helpSection: {
    get: startProcessDomain + "/v1/help-list",
    download: startProcessDomain + "/v1/download-help"
  },
};

Vue.prototype.$apiEndpoints = $apiEndpoints;
export { $apiEndpoints };
