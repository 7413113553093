<template>
  <div id="header">
    <!-- As a heading -->
    <nav
      class="navbar pompe-navbar light"
      style="justify-content: space-around;"
    >
      <div
        class="
          custom-header-container
          container-fluid-nav
          text-center
        "
        :class="
          $route.meta.layout !== 'login' ? 'custom-padding' : 'login-padding'
        "
        style="width: 1100px;"
      >
        <mq-layout :mq="['md', 'lg', 'xl']">
          <div class="custom-nav-container" style="display: flex;">
            <div class="logo-container">
              <img
                class="sanofi-logo"
                :src="`${getDomain}/api/v1/files/ed42a58c-50c4-4c65-988e-cf0782553d10/content`"
                alt="sanofi-logo-header"
              />
            </div>
            <div class="web-title__desktop">
              <span class="h6">{{ appName }}</span>
            </div>
          </div>
        </mq-layout>

        <sub-header
          v-if="$route.meta.layout !== 'login'"
          :userData="userData"
        />
      </div>
    </nav>

    <div class="pompe-second-navbar">
      <nav
        v-if="$route.meta.layout !== 'login'"
        class="navbar second-navbar"
        style="width: 1100px;"
      >
        <b-nav>
          <b-nav-item
            v-for="(item, index) in nav"
            :key="index"
            :active-class="isActive(item.href) ? 'active' : ''"
            :active="isActive(item.href)"
            @click="goTo(item.href)"
          >
            <span> {{ item.text }} </span>
          </b-nav-item>
        </b-nav>
      </nav>
    </div>
  </div>
</template>

<script>
// Vuex
import { 
  mapState,
  mapMutations,
  mapGetters,
} from "vuex";

export default {
  name: "headerNav",
  computed: {
    appName() {
      return process.env.VUE_APP_NAME.toUpperCase();
    },
    getDomain() {
      return process.env.VUE_APP_DOMAIN;
    },
    ...mapState({
      activeNav: (state) => state.header.activeNav,
      nav: (state) => state.header.nav,
    }),
    ...mapGetters({
      userData: 'general/getUserData'
    }),
  },
  methods: {
    ...mapMutations({
      setActiveNav: "header/activeNav",
    }),
    goTo(path) {
      if (this.$route.path !== path) location.href = path;
    },
    isActive(path) {
      return this.$route.path === path && this.$route.path.includes(path);
    },
  },
};
</script>

<style lang="scss">
@import "@/style/color.scss";
.custom-nav-container {
  margin-bottom: 0.3em;
}
.pompe-second-navbar {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #e3e3e3;
  background-color: #f9f9f9;

  .navbar {
    padding: 0.2rem 0.5rem 0.3rem 0.5rem !important;
  }
  a {
    text-decoration: none;
    color: $primary-ui !important;
  }

  .nav-item {
    font-size: 0.8rem;
    font-weight: 100 !important;

    .active {
      background-color: $primary-ui !important;
    }

    .nav-link {
      text-align: left;
      font-weight: 300;
      font-size: 14px;

      &:hover {
        background-color: $primary-ui !important;
        color: #fff !important;
      }
      &.active {
        background-color: $primary-ui !important;
        color: #fff !important;
      }
    }
  }
}
.menu-centered {
  display: flex;
  justify-content: center;
}
.navbar-brand {
  font-weight: lighter !important;
}
.custom-padding {
  padding: 5px 0 5px 0;
}
.login-padding {
  padding: 2px 10px 5px 10px;
}

.dark {
  background-color: $primary-ui !important;
  color: #fff !important;
}

.light {
  background-color: #fff !important;
  color: $primary-ui !important;
}

.web-title__desktop {
  width: 300px;
}

.pompe-navbar {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #e3e3e3;

  .logo-container {
    border-right: 1px solid #d9d9d9;
    height: 45px;
    margin-top: 3px;
    margin-right: 0.2em;
  }

  .sanofi-logo {
    margin-top: -0.1em;
    width: 100px;
  }

  a {
    text-decoration: none;
    color: $primary-ui !important;
  }

  .nav-item {
    font-size: 0.8rem;
    font-weight: 100 !important;
    padding: 0.2em; 

    .active {
      background-color: $primary-ui !important;
    }

    .nav-link {
      text-align: left;
      margin-bottom: 0;
      font-weight: 300;
      font-size: 14px;

      &:hover {
        background-color: $primary-ui !important;
        color: #fff !important;
      }
      &.active {
        background-color: $primary-ui !important;
        color: #fff !important;
      }
    }
  }
}
.profile-image {
  width: 40px;
  border-radius: 50%;
}
</style>
