import { getPatientRef } from '../helper/getPatientRef';
import { mapActions } from 'vuex';

/**
 * Start process mixin
 */
export default {
  methods: {
    ...mapActions({
      assignTabs: 'tabs/assignTabs',
      setPatientRefAssessment: 'assessment/reqPatientRef',
      setPatientRef: "creation/reqPatientRef",
      setPatientData: "creation/setPatientData"
    }),
    // Start New Visit
    goToNewVisit(patientID) {
      this.$requestService.post(
        this.$apiEndpoints.startProcessNewVisit + `?businessKey=${patientID}`,
        {},
        (response) =>
          this.handleNewVisitStartSuccess(response, patientID),
      );
    },
    async handleNewVisitStartSuccess(response, patientID) {
      const patientData = await getPatientRef(patientID);
      const { ref } = patientData.data;
      await this.setPatientRef(ref);

      const { nav } = response.data;
      await this.assignTabs(nav);

      this.$router.push({
        name: 'addPatient',
        params: {
          tab: response.data.page,
          id: patientID,
          state: 'overview-visit'
        }
      });
    },
    // Start New Assessment
    goToAssessment(patientID) {
      this.$requestService.post(
        this.$apiEndpoints.startProcessAssessment + `?businessKey=${patientID}`,
        {},
        (response) => this.handleAssessmentStartSuccess(response, patientID)
      );
    },
    async handleAssessmentStartSuccess(response, patientID) {
      const patientData = await getPatientRef(patientID);
      const { ref } = patientData.data;
      await this.setPatientRefAssessment(ref);

      const { nav } = response.data;
      await this.assignTabs(nav);

      this.$router.push({
        name: 'assessmentData',
        params: {
          tab: response.data.page,
          id: patientID,
          state: 'overview-assessment',
        }
      });
    },
    async handleAssessmentStartEditSuccess(response, patientID) {
      const patientData = await getPatientRef(patientID);
      const { ref } = patientData.data;
      await this.setPatientRefAssessment(ref);

      const { nav } = response.data;
      await this.assignTabs(nav);
      
      this.$router.push({
        name: 'assessmentData',
        params: {
          tab: response.data.page,
          id: patientID,
          state: 'edit'
        }
      });
    },
    // Open Assessment Overview
    startAssessmentOverviewProcess(patientID) {
      this.$requestService.post(
        this.$apiEndpoints.startProcessAssessmentOverview + `?businessKey=${patientID}`,
        {},
        this.handleAssessmentOverviewStartSuccess,
        this.handleAssessmentOverviewStartFailed
      );
    },
    // Edit Visit
    startEditVisit(id) {
      this.$requestService.post(
        this.$apiEndpoints.startProcessEditVisit +
          `?businessKey=${this.$route.params.id}&refKey=${id}`,
        {},
        this.handleEditVisitSuccess,
        this.handleEditVisitFailed
      );
    },
    async handleEditVisitSuccess(response) {
      const patientData = await getPatientRef(this.$route.params.id);

      const { ref } = patientData.data;
      await this.setPatientRef(ref);

      const { nav } = response.data;
      await this.assignTabs(nav);

      await this.setPatientData(this.$route.params.id)

      this.$router.push({
        name: 'addPatient',
        params: {
          tab: response.data.page,
          id: this.$route.params.id,
          state: 'edit-visit'
        }
      });
    }
  }
}