/**
 * This will handle HTTP request, and it's common outcome
 * Imported in main.js so this will be imported on
 *    /pages/Patient/Creation & /pages/Patient/Assessment
 * So this wil be just availbale in form pages
 */
import Swal from "sweetalert2";
import { mapState, mapMutations, mapActions } from "vuex";

let formType = window.location.pathname.includes("/assessment-data")
  ? "assessment"
  : "creation";

export default {
  data: () => ({
    currentField: '',
  }),
  computed: {
    ...mapState({
      selectedTab: (state) => state[formType].selectedTab,
      nav: (state) => state[formType].nav, 
    }),
  },
  methods: {
    ...mapMutations({
      setSelectedTab: `${formType}/setSelectedTab`,
    }),
    ...mapActions({
      assignTabs: 'tabs/assignTabs',
      reconstructTab: 'tabs/reconstructTabs',
    }),
    getSequence() {
      this.$emit('get-sequence', this.content[this.$route.params.tab].seq);
    },

    /**
     * This will handle GET request for all page, based on tab params
     */
    getFormData() {
      this.getDataLoading = true;

      this.$requestService
        .get(
          this.$apiEndpoints.getSpecificPage + `/${this.$route.params.tab}`,
          {
            params: {
              businessKey: this.$route.params.id,
            },
          },
          this.handleGetDataSuccess,
          this.handleFailedFormRequest
        )
        .then(() => {
          this.getSequence();
          this.getDataLoading = false;
        });
    },
    /**
     * Update form data, PUT Request
     */

    requestUpdateForm() {
      this.$requestService
        .put(
          this.$apiEndpoints.getSpecificPage +
            `/${this.$route.params.tab}?businessKey=${this.$route.params.id}`,
          this.content[this.$route.params.tab],
          this.handleUpdateFormSuccess,
          this.handleFailedFormRequest
        )
        .then(() => {
          this.updateDataLoading = false;
          this.$bvToast.hide("loadingToast");
        })
    },
    /**
     * Handle if update form is success
     */
    handleUpdateFormSuccess(response) {
      // Set new navs, if there's any
      if (response.data.nav.length > 0) {
        this.assignTabs(response.data.nav);
        // Reconstruct nav, based on each of the status
        this.reconstructTab();
      }
      this.currentField = '';
    },
    /**
     * Update Form Field Value
     */
    updateFieldValue(params) {
      const { parent, field, value, index } = params;

      this.currentField = field;

      if (index !== null && index > -1) {
        this.content[this.$route.params.tab][parent][index][field] = value;
      } else {
        this.content[this.$route.params.tab][field] = value;
      }
    },
    /**
     *  Save form data, POST Request
     */
    requestSaveForm() {
      this.saveDataLoading = true;

      this.$requestService
        .post(
          this.$apiEndpoints.getSpecificPage +
            `/${this.$route.params.tab}?businessKey=${this.$route.params.id}`,
          this.content[this.$route.params.tab],
          this.handleSaveFormSuccess,
          this.handleFailedFormRequest
        )
        .then(() => {
          this.saveDataLoading = false;
        });
    },
    /**
     * Handle if save form is success
     */
    handleSaveFormSuccess(response) {
      // Set new navs, if there's any
      if (response.data.nav.length > 0) {
        this.assignTabs(response.data.nav);
        // Reconstruct nav, based on each of the status
        this.reconstructTab();
      }

      if (this.$route.params.tab !== "discontinuation") {
        if (this.$route.params.tab === "vitalSigns") {
          this.openModal();
          const { stateTypeOpt } = response.data.options;
          this.audit.options = [ ...stateTypeOpt ];
          this.audit.content = { ...response.data.content.auditAssessment };
        } else if (
          this.$route.params.tab === "demographicData" &&
          this.content.demographicData.patientConfirmedDiagnosisOfPompe ===
            false
        ) {
          this.$router.push("/web/summary");
        } else {
          this.checkFormStatus(response.data.page, null, "POST");
        }
      } else {
        if (
          this.content.discontinuation.reasonForDiscontinuation !==
          "patientDeath"
        ) {
          this.checkFormStatus(response.data.page, null, "POST");
        } else {
          this.$router.push("/web/summary");
        }
      }

      this.currentField = '';
    },
    /**
     * Confirmation signed assessment
     */
    assessmentConfirmation(userData) {
      Swal.fire({
        title: "Confirm",
        text: `By pressing okay the assessment with id ${this.$route.params.id} 
              will be signed by User ${userData.firstName} ${userData.lastName}`,
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Okay",
      }).then((result) => {
        if (result.isConfirmed) {
          this.requestSaveForm();
        }
      });
    },
    /**
     * This will handle failed HTTP request
     */
    handleFailedFormRequest(e) {
      if (e.response.status === 400 || e.response.status === 422) {
        // this.errors = e.response.data.errors[this.selectedTab]; ===> this is the old one
        this.errors = e.response.data.errors;

      } else if (e.response.status === 409) {
        this.errors = e.response.data.errors[0];

        if (this.errors.errorCode === 'page.previous.incomplete') {
          this.goToPreviousPage(this.errors.field);
        }
      } else {
        this.notification.type = "danger";
        this.notification.message = e.response.data.message || e.message;
        this.notification.isActive = true;
      }
    },
    /**
     * Move back to previous page
     * @param String - current page
     */
    goToPreviousPage(currPage) {
      let index = this.nav.findIndex(el => el.page === currPage);
      if (index > -1) {
        this.$router.push({
          name: formType === 'creation' ? 'addPatient' : 'assessmentData',
          params: { tab: this.nav[index-1].page, id: this.$route.params.id },
        });
        this.setSelectedTab(this.nav[index-1].page);
      }
    },
    /**
     * This will handle failed GET request outside form pages
     */
    handleFailed(e) {
      if (e.response.status !== 400 || e.response.status !== 422) {
        this.notification.type = "danger";
        this.notification.message = e.response.data.message || e.message;
        this.notification.isActive = true;
      }
    },
  },
};
