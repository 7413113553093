<template>
  <div id="phone-number-component">
    <b-form-group>
      <label v-if="label !== ''" :for="name"> {{ label }} </label>
      <b-input-group>
        <input
          v-model="localValue"
          :id="`${name}-${index || ''}`"
          type="text"
          class="form-control form-control-sm"
          v-mask="'+##-############'"
          :placeholder="placeholder"
          :name="name"
          autocomplete="off"
          :readonly="handleLogicStatement(name, 'disable') || isDisable"
          @blur="$emit('blurEvent', name, index)"
          @input="$emit('input', $event.target.value)"
        />
        <small class="text-muted"> {{ additionalMessage }} </small>
        <error-message-new
          v-if="!fieldState"
          :index="index"
          :errors="errors"
          :fieldName="name"
          :parent="parent"
        />
      </b-input-group>
    </b-form-group>
  </div>
</template>

<script>
import { $hasField } from '@/helper/globalFunction.js';

export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    name: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      default: null
    },
    placeholder: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number],
      default: null,
    },
    size: {
      type: String,
      default: "sm"
    },
    isDisable: {
      type: Boolean,
      default: null,
    },
    parent: {
      type: String,
      default: null,
    },
    additionalMessage: {
      type: String,
      default: '',
    },
    showError: {
      type: Boolean,
      default: true,
    },
    errors: {
      type: [Array],
      default: () => []
    },
    logic: {
      type: Object,
      default: () => ({})
    },
    content: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    localValue: null,
  }),
  watch: {
    value: {
      handler(val) {
        this.localValue = this.type === 'number' ? val.toString() : val;
      },
      immediate: true,
    }
  },
  computed: {
    fieldState() {
      return this.$hasField({ 
        name: this.name, 
        errors: this.errors, 
        index: this.index, 
        parent: this.parent 
      }) && this.showError
    },
  },
  methods: { $hasField }
}
</script>
